import React, { Component } from "react"
import { graphql } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
// import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
// import StaticHeading from "./index/StaticHeading"
// import Sizzle from "./index/Sizzle"
// import ImageText from "./index/ImageText"
// import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
// import FirstTime from "./index/FirstTime"
// import InTheCommunity from "./index/InTheCommunity"
// import ReferringProvider from "./index/ReferringProvider"
// import LearnMoreCards from "./index/LearnMoreCards"
// // import ImageTexts from "../components/ImageTexts"
// import Exparel from "./index/Exparel"
// import ASIRD from "./index/ASIRD"
import StaticHero from "./index/StaticHero"
import { Image, Text } from "../components/Core"
import { telephoneStrip } from "../utils/utils"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const { pageContext } = this.props
    let post
    const { language } = this.props.pageContext

    if (pageContext && pageContext.language) {
      switch (language) {
        case "en":
          post = this.props.data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = this.props.data.spanishIndex.nodes[0]

          break
        default:
          post = this.props.data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = this.props.data.allUniquePagesJson.nodes[0]
    }

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    let allReviews = this.props.data.allReviews.nodes
    if (language === "es") allReviews = this.props.data.allReviewsEs.nodes
    const sortedReviews = sortReviewsByDate(allReviews)

    // shuffleArray(allReviews);

    let fixedFacewallReviews = []
    if (sortedReviews.length >= 8) {
      fixedFacewallReviews = sortedReviews.slice(0, 8)
    } else {
      fixedFacewallReviews = sortedReviews.slice(0, 4)
    }

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: post.metaTitle,
        description: post.metaDescription,
        path: this.props.location.pathname
      }
    }

    const location = this.props.data.location.nodes[0].locationData

    return (
      <SharedStateProvider>
        <Layout
          className={`main-homepage home ${language === "es" ? "es" : null}`}
          language={language}
          layoutClass="homepage-fab"
          noFab
          pageTitle="main-homepage">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            schemaData={{}}
            pathname={this.props.location.pathname}
            lang={language}
          />

          <div className="home__logo">
            <Image useAR publicId="AOMS_PROS/DEV/logo" />
          </div>

          <StaticHero
            post={post}
            heading={post.hero.heading}
            subheading={post.hero.blurb}
            bgImage={post.hero.bgImage}
            language={language}
          />

          <div className="home__featured">
            <div className="container">
              <h2 className="has-text-centered">{post.featured.heading}</h2>
              <p className="has-text-centered">{post.featured.blurb}</p>

              <div className="home__procedures">
                {post.featured.procedures.map(item => (
                  <div key={item.heading} className="home__procedure-item">
                    <Image publicId={item.image} />
                    <h3>{item.heading}</h3>
                    <p>{item.blurb}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="home__about">
            <div className="container medium">
              <Text text={post.about} />
            </div>
          </div>

          <footer>
            <div className="container">
              <div className="home__footer">
                <div className="footer__logo">
                  <Image publicId="AOMS_PROS/DEV/logo-mark" />
                </div>
                <div className="footer__location">
                  <h4>{location.locationName}</h4>
                  <a
                    title="Get directions to our office"
                    href={location.googlePlaceLink}
                    target="_blank">
                    <p className="mb-0 mt-0">{`${location.streetNumber} ${location.streetName}`}</p>
                    <p className="mb-0 mt-0">{`${location.cityName}, ${location.stateAbbr} ${location.cityZip}`}</p>
                  </a>
                  <a
                    title="Give us a call"
                    target="_blank"
                    href={`tel:+1${telephoneStrip(location.phone)}`}>
                    <p>{location.phone}</p>
                  </a>
                  <p>Monday–Friday: 8:00 AM–5:00 PM</p>
                </div>
              </div>
            </div>
          </footer>

          {/* <div className="static-container" style={staticStyle}>
            {post.hasSizzle ? (
              <Sizzle
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                post={post}
                language={language}>
                <StaticHeading
                  playing={this.state.playing}
                  youtube={post.sizzleYoutube}
                  heading={post.hero.heading}
                  subheading={post.hero.subheading}
                  language={language}
                />
              </Sizzle>
            ) : (
              <StaticHero
                post={post}
                heading={post.hero.heading}
                subheading={post.hero.subheading}
                bgImage={post.hero.bgImage}
                language={language}
              />
            )} */}

          {/* <ImageText language={language} post={post} textImage noReverse />

            <LearnMoreCards language={language} post={post.learnMoreCards} /> */}

          {/* <FixedFacewallHomepage
              language={language}
              heading={
                language === "es"
                  ? "Escuche Acerca de Nuestros Pacientes"
                  : "Hear From Our Patients"
              }
              reviews={fixedFacewallReviews}
            /> */}

          {/* <HeadingVideo post={post} /> */}

          {/* <AAAHC post={post.homeAAAHC} language={language}/> */}

          {/* <InTheCommunity
              colorBack
              language={language}
              data={post.inTheCommunity}
            />

            <ReferringProvider
              // colorBack
              textOnly
              language={language}
              data={post.referringProvider}
            />

            <Exparel post={post.homeExparel} />
            <ASIRD post={post.homeAsird} />

            <FirstTime colorBack language={language} post={post} noReverse />

            {post.homeRatingsReviews.hasThisSection && (
              <AllRatingsReviews
                colorBack={false}
                animation
                heading={post.homeRatingsReviews.heading}
                language={language}
                buttonHref={post.homeRatingsReviews.buttonOne.href}
                buttonText={post.homeRatingsReviews.buttonOne.buttonText}
              />
            )} */}
          {/* </div> */}
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { ne: "ESP" } }
    ) {
      nodes {
        ...FacewallData
      }
    }
    allReviewsEs: allSpanishReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ESP" } }
      limit: 4
    ) {
      nodes {
        title
        reviewerName
        reviewType
        reviewLanguage
        thumbnailPublicId
        mainProcedureDone
        monthYear {
          month
          year
        }
      }
    }
    location: allLocationsJson(limit: 1) {
      nodes {
        locationData {
          cityName
          cityZip
          googlePlaceLink
          locationName
          phone
          stateAbbr
          stateName
          streetName
          streetNumber
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          heading
          blurb
        }
        featured {
          heading
          blurb
          procedures {
            heading
            blurb
            image
          }
        }
        about
        schemas {
          presets
          custom
        }
        #   hasSizzle
        #   hero {
        #     heading
        #     subheading
        #     bgImage
        #   }
        #   homeSectionOne {
        #     buttons {
        #       button {
        #         buttonText
        #         href
        #         appearance
        #         destination
        #       }
        #     }
        #     heading
        #     imageId
        #     text
        #   }
        #   imageTexts {
        #     hasThisSection
        #     textLeft
        #     leftColWidth
        #     leftContentColWidth
        #     middleColWidth
        #     rightColWidth
        #     text
        #     imageId
        #     buttons {
        #       button {
        #         buttonText
        #         appearance
        #         buttonText
        #         href
        #         destination
        #       }
        #     }
        #   }
        #   homeExparel {
        #     hasThisSection
        #     imageId
        #     blurb
        #     button {
        #       buttonText
        #       href
        #     }
        #   }
        #   homeAsird {
        #     blurb
        #     hasThisSection
        #     imageId
        #     button {
        #       buttonText
        #       href
        #     }
        #   }
        #   homeAAAHC {
        #     blurb
        #     heading
        #     hasThisSection
        #     backgroundImage
        #     logos {
        #       imageId
        #     }
        #     button {
        #       useButton
        #       buttonText
        #       href
        #     }
        #   }
        #   learnMoreCards {
        #     blurb
        #     hasThisSection
        #     heading
        #     cards {
        #       blurb
        #       heading
        #       imageId
        #       button {
        #         buttonText
        #         href
        #       }
        #     }
        #     buttons {
        #       button {
        #         buttonText
        #         appearance
        #         buttonText
        #         href
        #         destination
        #       }
        #     }
        #   }
        #   homeRatingsReviews {
        #     hasThisSection
        #     heading
        #     buttonOne {
        #       buttonText
        #       href
        #     }
        #   }
        #   homeHeadingVideo {
        #     hasThisSection
        #     heading
        #     youtube
        #     youtubePhotoPublicId
        #     youtubePhotoPublicIdMobile
        #   }
        #   inTheCommunity {
        #     buttons {
        #       button {
        #         buttonText
        #         href
        #         appearance
        #         destination
        #       }
        #     }
        #     hasThisSection
        #     heading
        #     imageId
        #     leftRight
        #     subheading
        #     text
        #   }
        #   referringProvider {
        #     youtube
        #     imageCaption
        #     buttons {
        #       button {
        #         buttonText
        #         href
        #         appearance
        #         destination
        #       }
        #     }
        #     hasThisSection
        #     heading
        #     imageId
        #     text
        #   }
        #   firstTimePatient {
        #     buttons {
        #       button {
        #         href
        #         buttonText
        #         destination
        #         appearance
        #       }
        #     }
        #     heading
        #     text
        #   }
        #   metaTitle
        #   sizzleYoutube
        #   metaDescription
        #   title
      }
    }
    # spanishIndex: allSpanishUniquePagesJson(filter: { title: { eq: "/es/" } }) {
    #   nodes {
    #     hasSizzle
    #     mobileHeading
    #     staticHero {
    #       heading
    #       subHeading
    #       sideImage
    #       logo
    #       backgroundImage
    #     }
    #     homeSectionOne {
    #       buttonOne {
    #         buttonText
    #         href
    #       }
    #       buttonTwo {
    #         buttonText
    #         href
    #       }
    #       buttons {
    #         button {
    #           buttonText
    #           href
    #           appearance
    #           destination
    #         }
    #       }
    #       heading
    #       imageId
    #       text
    #     }
    #     imageTexts {
    #       hasThisSection
    #       textLeft
    #       leftColWidth
    #       leftContentColWidth
    #       middleColWidth
    #       rightColWidth
    #       text
    #       imageId
    #       buttons {
    #         button {
    #           buttonText
    #           appearance
    #           buttonText
    #           href
    #           destination
    #         }
    #       }
    #     }
    #     homeExparel {
    #       hasThisSection
    #       imageId
    #       blurb
    #       button {
    #         buttonText
    #         href
    #       }
    #     }
    #     homeAsird {
    #       blurb
    #       hasThisSection
    #       imageId
    #       button {
    #         buttonText
    #         href
    #       }
    #     }
    #     homeAAAHC {
    #       blurb
    #       heading
    #       hasThisSection
    #       backgroundImage
    #       logos {
    #         imageId
    #       }
    #       button {
    #         useButton
    #         buttonText
    #         href
    #       }
    #     }
    #     learnMoreCards {
    #       blurb
    #       hasThisSection
    #       heading
    #       cards {
    #         blurb
    #         heading
    #         imageId
    #         button {
    #           buttonText
    #           href
    #         }
    #       }
    #     }
    #     homeRatingsReviews {
    #       hasThisSection
    #       heading
    #       buttonOne {
    #         buttonText
    #         href
    #       }
    #     }
    #     homeHeadingVideo {
    #       hasThisSection
    #       heading
    #       youtube
    #       youtubePhotoPublicId
    #       youtubePhotoPublicIdMobile
    #     }
    #     seoHeading
    #     inTheCommunity {
    #       buttons {
    #         button {
    #           buttonText
    #           href
    #           appearance
    #           destination
    #         }
    #       }
    #       hasThisSection
    #       heading
    #       imageId
    #       leftRight
    #       subheading
    #       text
    #     }
    #     referringProvider {
    #       youtube
    #       imageCaption
    #       buttons {
    #         button {
    #           buttonText
    #           href
    #           appearance
    #           destination
    #         }
    #       }
    #       hasThisSection
    #       heading
    #       imageId
    #       text
    #     }
    #     firstTimePatient {
    #       buttons {
    #         button {
    #           href
    #           buttonText
    #           destination
    #           appearance
    #         }
    #       }
    #       heading
    #       text
    #     }
    #     metaTitle
    #     sizzleYoutube
    #     metaDescription
    #     title
    #     svgHeading
    #   }
    # }
  }
`

export default IndexPage
