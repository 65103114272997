import React from "react"
import { Text } from "../../components/Core"
import { Columns } from "../../components/Containers"

const StaticHero = ({ heading, subheading, bgImage, language }) => {
  const imgStyles = {}
  if (bgImage) {
    imgStyles.backgroundImage = `url(https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/${bgImage})`
    imgStyles.backgroundPosition = "center top"
    imgStyles.backgroundSize = "cover"
    imgStyles.backgroundRepeat = "no-repeat"
    imgStyles.backgroundColor = "transparent"
  }
  return (
    <div className="home__hero">
      <div className="container medium has-text-centered">
        <Text as="h1" className="hero-heading" text={heading} />
        <Text as="p" className="mb-0" text={subheading} />
      </div>
    </div>
  )
}

export default StaticHero
